import './App.css';
import {
    App, f7ready, View
} from "framework7-react";
import Registration from "./Screens/Registration";
import Startup from "./Screens/Startup";
import Main from "./Screens/Main";
import MyCars from "./Screens/MyCars";
import CarForm from "./Screens/advanced car form/CarForm";
import SimpleCarForm from "./Screens/simple car form/SimpleCarForm";
import CarEdit from "./Screens/car edit/CarEdit";
import ProfileEdit from "./Screens/ProfileEdit";
import LegalEntityForm from "./Screens/LegalEntityForm";
import MapScreen from "./Screens/MapScreen";
import Splash from "./Screens/Splash";
import ChangeLang from "./Screens/ChangeLang";
import DeleteCar from "./Screens/DeleteCar";
import ChangeLanguage from "./Screens/ChangeLanguage";
import Notifications from "./Screens/Notifications";



function MyApp() {
    f7ready(() => {
    });
    const f7params = {
        name: 'My App',
        theme: 'ios',
        dark: false,
        routes: [
            {
                path: '/',
                component: Startup,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/register',
                component: Registration,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/simplecarform',
                component: SimpleCarForm,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/main',
                component: Main,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/profileedit',
                component: ProfileEdit,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/notifications',
                component: Notifications,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/legalentityform',
                component: LegalEntityForm,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/mycars',
                component: MyCars,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/carform',
                component: CarForm,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/caredit',
                component: CarEdit,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/maps',
                component: MapScreen,
                options: {
                    clearPreviousHistory: true
                }

            },
            {
                path: '/splash',
                component: Splash,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/delete',
                component: DeleteCar,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/lang',
                component: ChangeLang,
                options: {
                    clearPreviousHistory: true
                }
            },
            {
                path: '/changelang',
                component: ChangeLanguage,
                options: {
                    clearPreviousHistory: true
                }
            },
        ],
    };
  return (
      <App name="My App" {...f7params}>
          <View transition='f7-fade' main url="/splash" />
      </App>
  );
}

export default MyApp;
