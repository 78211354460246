import axios from 'axios';

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`


export const getCar = async (id) => {
    try {
        const res = await axios.get(`https://dev.selluzauto.uz/api/car/${id}/`)
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const getCars = async () => {
    try {
        const res = await axios.get('https://dev.selluzauto.uz/api/car/')
        return res.data
    } catch (e) {
        console.log(e)
    }
}


export const createCar = async (data) => {
    try {
        const res = await axios.post('https://dev.selluzauto.uz/api/car/', data)
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const updateCar = async (id, data) => {
    try {
        const res = await axios.put(`https://dev.selluzauto.uz/api/car/${id}/`, data)
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const deleteCar = async (id, data) => {
    try {
        const res = await axios.delete(`https://dev.selluzauto.uz/api/car/${id}/`, {data: data})
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const fetchNext = async (next) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

        const res = await axios.get(next);
        return res.data
    } catch (e) {
        console.log(e)
    }
}


export const getHandbooks = async () => {
    try {
        const res = await axios.get(`https://dev.selluzauto.uz/api/generic/`)
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const getMinPrice = async (brand_id, model_id, trans_id, year, mileage, color_id) => {
    try {
        const res = await axios.get(`https://dev.selluzauto.uz/api/car/min_price?${brand_id && `brand_id=${brand_id}&`}${model_id && `model_id=${model_id}&`}${trans_id && `trans_id=${trans_id}&`}${year && `year=${year}&`}${mileage && `mileage=${mileage}&`}${color_id && `color_id=${color_id}`}`)
        return res.data
    } catch (e) {
        console.log(e)
    }
}

export const getHandbook = async (type) => {
    try {
        const res = await axios.get(`https://dev.selluzauto.uz/api/generic/${type}/`)
        return res.data
    } catch (e) {
        console.log(e)
    }
}