import React, {useEffect, useRef, useState} from 'react'
import {
    BlockHeader,
    BlockTitle,
    Button,
    Card,
    CardContent,
    CardHeader, f7, f7ready,
    Link,
    List,
    ListItem,
    Page, Popover
} from "framework7-react";
import sellzLogo from '../assets/sellz_logo.jpg'
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {setCars} from "../store/store";
import {fetchNext, getCars, updateCar} from "../api/car";
import {useTranslation} from "react-i18next";


const statusTypes = {
    'moderation': {
        text_ru: "На проверке",
        text_uz: "Tekshiruvda",
        bgColor: 'rgba(229,218,6,0.5)',
        textColor: 'rgb(94,93,64)'
    },
    'active': {
        text_ru: "Активный",
        text_uz: "Aktiv",

        bgColor: 'rgba(71,222,30,0.5)',
        textColor: 'rgb(37,63,32)'
    },
    'inactive': {
        text_ru: "Неактивный",
        text_uz: "Aktiv emas",
        bgColor: 'rgba(143,143,139,0.2)',
        textColor: 'rgba(143,143,139)'
    },
    'fixing': {
        text_ru: "На исправлении",
        text_uz: "Tuzatishlar kerak",
        bgColor: 'rgba(222,104,117,0.5)',
        textColor: 'rgb(210,15,54)'
    }
}

const MyCars = () => {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });
    const [status, setStatus] = useState('');

    const {t, i18n} = useTranslation();


    const [nextURL, setNextURL] = useState('');
    const observerRef = useRef(null);
    const lastCarRef = useRef(null);
    const [currentCars, setCurrentCars] = useState([])

    function handleBackBtn() {
        f7.views.main.router.navigate('/main');
    }

    function handleMainBtn () {
        window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
        f7.views.main.router.navigate('/carform');
    }

    const fetchNextCars = async (next) => {
        const res = await fetchNext(next)
        setCurrentCars((prevState)=> [...prevState, ...res.results])
        setNextURL(res.next)
        console.log(currentCars)
    }

    useEffect(() => {
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMainBtn);
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.onEvent('backButtonClicked', handleBackBtn);
        window.Telegram.WebApp.MainButton.text = t('addCarBtn')

        window.Telegram.WebApp.MainButton.isVisible = true;

        return(() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        });
    }, [window.Telegram.WebApp.MainButton.isVisible])

    useEffect(() => {
        const fetchCars = async () => {
            const data = await getCars();
            setNextURL(data.next)
            setCurrentCars(data.results)
            setStatus(data?.results?.status)
            console.log(data)
        }
            fetchCars();
    }, [])

    useEffect(() => {
        if (observerRef.current) observerRef.current.disconnect();

        observerRef.current = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && nextURL) {
                    fetchNextCars(nextURL);
                }
            },
            { threshold: 1 }
        );

        if (lastCarRef.current) {
            observerRef.current.observe(lastCarRef.current);
        }

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
    }, [currentCars, nextURL]);



    return (
        <Page onPageAfterOut={() => {
            window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainBtn);
            window.Telegram.WebApp.offEvent('backButtonClicked', handleBackBtn);
        }} >
            <BlockTitle style={{fontSize: 25, lineHeight: 1.2}}>{t('myCars')}</BlockTitle>
            <List dividersIos mediaList insetIos strongIos>
            { (currentCars.length === 0) ?  (<div>{t('yetEmpty')} :(</div>) : currentCars.map((car, index) => {

                    return (<ListItem
                        onClick={() => { f7.view.main.router.navigate("/caredit", {props: {car}}); }}
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%'}}
                        key={index}
                        link="#"
                        title={car.model_id.name}
                        after=""
                        subtitle={`${t('cost')}: ${car.price_usd} $`}
                        text={`${t('views')}: ${car?.views}`}
                    >
                        <div ref={index === currentCars.length - 1 ? lastCarRef : null}/>
                        <div style={{display: 'inline-block'}} onClick={(e) => {
                            if (car?.status === 'active' || car?.status === 'inactive') {
                            e.stopPropagation();
                        }}}>
                            <Link popoverOpen={(car?.status === 'active' || car?.status === 'inactive') ? '.filter-popover' : null}>
                                <span  style={{
                                    marginTop: 10,
                                    fontSize: 14,
                                    display: 'inline-block',
                                    backgroundColor: `${statusTypes[car?.status].bgColor}`,
                                    borderRadius: '8px',
                                    padding: '4px 12px',
                                    color: `${statusTypes[car?.status].textColor}`,
                                    fontWeight: 'bold',}}>{i18n.language === 'ru' ? statusTypes[car?.status].text_ru : statusTypes[car?.status].text_uz}</span>
                            </Link>

                        </div>
                        <img
                            slot="media"
                            style={{
                                borderRadius: '8px',
                                objectFit: 'cover'
                            }}
                            src={`https://selluzauto.uz/${car.images[0]}` || sellzLogo}
                            width="90"
                            height="90"
                        />
                        { <Popover arrow  className="filter-popover">
                            <div onClick={(e) => e.stopPropagation()}>
                                <List inset dividersIos>
                                    {car?.status === 'active' && <ListItem  onChange={() => {updateCar(car?.id, {status: 'inactive'}); setStatus('inactive'); f7.popover.close();}}   radio radioIcon="end" title={i18n.language === 'ru' ? 'Деактивировать' : 'Deaktivatsia'} value="active" name="demo-radio-end1" />}
                                    {car?.status === 'inactive' && <ListItem onChange={() => {updateCar(car?.id, {status: 'active'}); setStatus('active'); f7.popover.close();}}  radio radioIcon="end" title={i18n.language === 'ru' ? 'Активировать' : 'Aktiv kilish'} value="inactive" name="demo-radio-end1" />}
                                </List>
                            </div>
                        </Popover>}
                    </ListItem>)

            })}
            </List>

        </Page>)
}

export default MyCars;